import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Hero.css';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function Hero() {

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    axios.get('./carousel/content.json')
      .then(response => {
        const imagePaths = response.data.images.map(image => `./carousel/${image}`);
        setImageUrls(imagePaths);
      })
      .catch(error => {
        console.error('Error fetching carousel images:', error);
      });
  }, []);

  return (
    <div className="hero-container">
      <Carousel showThumbs={false} showStatus={false} interval={10000} infiniteLoop autoPlay>
        {imageUrls.map((imageUrl, index) => (
            <div key={index}>
              <img src={imageUrl} alt={`Carousel ${index + 1}`} />
            </div>
          ))}
      </Carousel>
      <div className="hero-content">
        <h1>Food Trucks voor elke gelegendheid</h1>
        <p>Maak uw evenement onvergetelijk met onze alles-in-één catering- en foodtruckverhuurdiensten.</p>
      </div>
    </div>
  );
}

export default Hero;