import React from 'react';
import './About.css';
//import foodTruckImage from '../assets/foodtruck.jpg';

const About = () => {
  return (
    <div id="about" className="about-container">
      <div className="about-text">
        <h2>Over Ons</h2>
        <p>Wij zijn een team van foodliefhebbers die dol zijn op het verzorgen van heerlijk eten voor uw evenementen. Onze foodtrucks zijn volledig uitgerust en bemand met vriendelijke professionals die vol passie geweldig eten aan onze klanten willen serveren.</p>
        <p>Onze missie is om uw evenement onvergetelijk te maken door smakelijk eten en uitstekende service te bieden. We zijn trots op ons werk en streven er altijd naar om uw verwachtingen te overtreffen.</p>
      </div>
      <div className="about-image">
        <img src='./about.png' alt="Food Truck" />
      </div>
    </div>
  );
};

export default About;
