import React from 'react';
import './Contact.css';

import Form from '../components/Form';

function Contact() {
  return (
    <div>
        <Form></Form>
    </div>
  );
}

export default Contact;