import React from 'react';
import './Events.css';
import TimeLine from '../components/TimeLine';

function Events(){
    return (
        <div>
            <TimeLine></TimeLine>
        </div>
    )
}

export default Events;
