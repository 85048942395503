import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './TimeLine.css';

function toTitleCase(str) {
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
}

function formatDate(dateString) {
  const [day, month, year] = dateString.split('/');
  return new Date(`${year}-${month}-${day}`);
}

function Lightbox({ imageUrl, onClose }) {

    useEffect(() => {
        // Add the no-scroll class to the body element when the lightbox is mounted
        document.body.classList.add('no-scroll');
    
        // Remove the no-scroll class when the lightbox is unmounted
        return () => {
          document.body.classList.remove('no-scroll');
        };
      }, []);

    const handleClickOutside = (event) => {
      if (event.target.classList.contains('lightbox-overlay')) {
        onClose();
      }
    };
  
    return (
      <div className="lightbox-overlay" onClick={handleClickOutside}>
        <div className="lightbox-content">
          <img src={imageUrl} alt="Lightbox" />
        </div>
      </div>
    );
  }

function TimeLine() {
  const [imageUrls, setImageUrls] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  useEffect(() => {
    axios
      .get('./events/2023/content.json')
      .then((response) => {
        const imagePaths = response.data.images.map((image) => ({
          url: `./events/2023/${image.title}`,
          date: formatDate(image.date),
        }));

        imagePaths.sort((a, b) => b.date - a.date);
        setImageUrls(imagePaths);
      })
      .catch((error) => {
        console.error('Error fetching timeline images:', error);
      });
  }, []);

  const openLightbox = (index) => {
    // Check if the lightbox is not already open before opening it
    if (!lightboxOpen) {
      setLightboxOpen(true);
      setLightboxIndex(index);
    }
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <div className="vertical-timeline">
      {imageUrls.map((item, index) => {
        const fileName = item.url.split('/').pop();
        const title = fileName.split('.')[0].replace(/_/g, ' ');
        const titleCaseTitle = toTitleCase(title);
        const timelineClass = index % 2 === 0 ? 'left' : 'right';

        return (
          <div
            key={index}
            className={`timeline-item ${timelineClass}`}
          >
            <div className="img-wrapper">
              <img src={item.url} alt={title} onClick={() => openLightbox(index)}/>
            </div>
            <div className="data-wrapper">
              <div className="title">{titleCaseTitle}</div>
              <div className="date">{item.date.toLocaleDateString()}</div>
            </div>
          </div>
        );
      })}

      {lightboxOpen && (
        <Lightbox
          imageUrl={imageUrls[lightboxIndex].url}
          onClose={closeLightbox}
        />
      )}
    </div>
  );
}

export default TimeLine;
