import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <div id="services" className="services-container">
      <h2>Onze Diensten</h2>
      <div className="services-grid">
        <div className="service">
          <h3>Food Truck Verhuur</h3>
          <p>Wij bieden een verscheidenheid aan foodtrucks die aansluiten bij de behoeften van uw evenement. Onze foodtrucks zijn volledig uitgerust en bemand om heerlijk eten aan uw gasten te serveren.</p>
        </div>
        <div className="service">
          <h3>Catering</h3>
          <p>Onze cateringdiensten bieden een heerlijke en zorgeloze ervaring voor uw evenement. We kunnen een menu op maat samenstellen dat past bij uw wensen en dieetvereisten.</p>
        </div>
        <div className="service">
          <h3>Vervoer</h3>
          <p>We vervoeren onze foodtrucks naar elke locatie van uw keuze, tijdige levering en in perfecte staat gegarandeerd.</p>
        </div>
      </div>
    </div>
  );
};

export default Services;
