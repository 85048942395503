import React, {useState} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Header.css';

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'

function Header() {
  const location = useLocation();
  const navigate = useNavigate();

  const scrollToSection = (sectionId) => {
    closeMobileMenu();

    if (location.pathname !== "/") {
      navigate("/");
    }else{
      scroll.scrollTo(sectionId, {
        smooth: true,
        offset: -100,
      });
    }
  }

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <header className="header-container">
      <div className="logo-container">
        <img src={'./logo_wide.png'} alt="Foodtruck - Allinone" className="logo" />
      </div>
      <div className='menu-icon' onClick={handleClick}>
        <FontAwesomeIcon color='black' icon={click ? faTimes : faBars} />
      </div>
      <nav className={click ? 'nav-menu active' : 'nav-menu'}>
        <ul>
          <li><Link to="/" onClick={closeMobileMenu}>Home</Link></li>
          <li><ScrollLink
              to="services"
              smooth={true}
              offset={-100}
              onClick={() => scrollToSection("services")}
            >Diensten</ScrollLink></li>
          <li><ScrollLink
              to="foodtrucks"
              smooth={true}
              offset={-100}
              onClick={() => scrollToSection("foodtrucks")}
            >Food Trucks</ScrollLink></li>
          <li><ScrollLink
              to="about"
              smooth={true}
              offset={-100}
              onClick={() => scrollToSection("about")}
            >Over Ons</ScrollLink></li>
          <li><a href="https://lekkervoorjebekje.be/" target="blank" rel='noopener noreferer'>Catering</a></li>
          <li><Link to="/events" onClick={closeMobileMenu}>Evenementen</Link></li>
          <li><Link to="/contact" onClick={closeMobileMenu}>Contact</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;