import React from 'react';
import './App.css';

import {
  Routes,
  Route,
  HashRouter 
} from "react-router-dom";

import Home from './pages/Home';
import Contact from './pages/Contact';
import Events from './pages/Events'
import Footer from './components/Footer';

import Header from './components/Header';

function App() {
  return (
   <div>
      <HashRouter>
        <Header></Header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />}/>
          <Route path="/events" element={<Events />}/>
        </Routes>
        <Footer></Footer>
      </HashRouter>
   </div>
  );
}

export default App;
