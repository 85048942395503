import React from 'react';
import './FoodTrucks.css';

import {Link} from 'react-router-dom';


function FoodTrucks() {
  return (
    <div id="foodtrucks" className="food-trucks-container">
      <h2>Onze Food Trucks</h2>
      <div className="food-trucks-grid">
        <div className="food-truck">
          <img src="./trucks/truck_1.jpg" alt="Food Truck 1" />
          <h3>Broodjes & Burgers</h3>
          <p>Huur onze gespecialiseerde foodtruck voor sappige hamburgers en geniet van een onvergetelijke smaakbeleving. Met hoogwaardige ambachtelijke burgers, een gevarieerd menu en vriendelijk personeel, zorgen we ervoor dat uw evenement een culinair succes wordt.</p>
          <button className="cta-button"><Link to="/contact">Book Now</Link></button>
        </div>
        <div className="food-truck">
          <img src="./trucks/truck_2.jpg" alt="Food Truck 2" />
          <h3>Ijsjes</h3>
          <p>Proef de heerlijke smaak van ambachtelijk Italiaans ijs. Laat uw gasten genieten van authentieke ijssmaken, bereid met liefde en vakmanschap.</p>
          <button className="cta-button"><Link to="/contact">Book Now</Link></button>
        </div>
        <div className="food-truck">
          <img src="./trucks/truck_3.jpg" alt="Food Truck 3" />
          <h3>Drank</h3>
          <p>Kies voor onze drank- en bierfoodtruck met koeling inbegrepen en maak uw evenement compleet. Laat uw gasten genieten van verfrissende drankjes en heerlijk bier, geserveerd met gemak en stijl.</p>
          <button className="cta-button"><Link to="/contact">Book Now</Link></button>
        </div>
      </div>
    </div>
  );
}

export default FoodTrucks;