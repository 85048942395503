import Hero from '../components/Hero';
import FoodTrucks from '../components/FoodTrucks';
import Services from '../components/Services';
import About from '../components/About';

function Home() {
    return (
     <div>
        <Hero></Hero>
        <Services></Services>
        <FoodTrucks></FoodTrucks>
        <About></About>
     </div>
    );
  }
  
  export default Home;