import React, { useRef } from 'react';
import './Form.css';
import emailjs from 'emailjs-com';

const Form = () => {
  const form = useRef();
  const sendEmail  = (event) => {
    event.preventDefault();

    console.log(form.current)

    emailjs.sendForm('service_r7efs2d', 'template_tcykx1e', form.current, '3nmHgr7v2PYb-H6VI')
      .then((result) => {
        alert("Uw bericht was successvol verzonden.")
    }, (error) => {
      alert("Er is een probleem met de server. Probeer het later opnieuw.")
    });
  }

  return (
    <div className="contact-container">
      <div className="col">
        <h2>Vragen?</h2>
        <h2>Foodtruck Boeken?</h2>
        <h2>Contacteer ons!</h2>
      </div>
      <div className="col">
        <form className="contact-form" ref={form}  onSubmit={sendEmail}>
          <input type="text" id="name" name="name" placeholder="Naam" required />

          <input type="email" id="email" name="email" placeholder="Email" required />

          <input type="tel" id="phone" name="phone" placeholder="Telefoon (optioneel)"/>

          <textarea id="message" name="message" placeholder="Bericht" required></textarea>

          <button type="submit">Verzenden</button>
        </form>
      </div>
    </div>
  );
};

export default Form;
