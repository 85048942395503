import React, {useState} from 'react';
import './Footer.css';

const Footer = () => {
  const [currentYear] = useState(new Date().getFullYear());

  return (
    <div className="footer-container">
      <p>Traiteur Johan Jackmaer | Foodtruck All in One &copy; 2020 - {currentYear} </p>
      <p>Powered by <a href='https://www.pandamoniumlabs.com' target="_blank" rel="noreferrer noopener">Pandamonium Labs</a> | Site design &amp; web development by SCRIPT &copy; 2022 - {currentYear}</p>
    </div>
  );
};

export default Footer;
